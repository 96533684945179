// Simply returns values from deeply nested appConfig and enables better error handling.
// So insead of: appConfig?.tenantConfig?.tenantId
// We can do: useTenantConfig().tenantId
export const useTenantConfig = () => {
  const appConfig = useAppConfig()
  const tenantConfig = appConfig?.tenantConfig
  if (!tenantConfig) {
    // If there is no tenant config, we need to update it by calling the back-end
    const result = useUpdateTenantConfig()
    console.info(`~tixx-platform~ ${process.server ? 'server' : 'client'} - updating appConfig from tenantConfig from API`)
    return result
    // throw new Error('The platform could not find configuration for this tenant. Please check that your account is active.')
  }
  return tenantConfig
}

export const useTenantId = () => {
  const { data: tenantId } = useFetch('/api/v2/_config/get-tenant-id') // This will be cached, don't worry
  // const tenantId = unref(data)
  console.info(`~tixx-platform~ ${process.server ? 'server' : 'client'} - useTenantId: ${tenantId.value}`)
  return tenantId
}

export const useFetchTenantConfig = () => {
  return useFetch(`/api/v2/_config/get/${useAppConfig().tixxAppScope}`)
}
