
export default defineNuxtPlugin({
  name: 'tixx-tenant-config',
  enforce: 'pre', // ensures this plugin is loaded before others
  async setup () {
    return {
      provide: {
        tenantConfig: await useTenantConfig() || {},
        tenantId: await useTenantId() || null
      }
    }
  }
})
