<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">

// Use appConfig to get custom tenant config
const appConfig = useAppConfig()

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${appConfig?.tenantConfig?.theme?.brandName || 'Tix Auth'}` : 'Tix Auth'
  }
})

</script>
