import SuperTokens from 'supertokens-web-js/index.js'
import Session from 'supertokens-web-js/recipe/session/index.js'
import ThirdPartyEmailPassword from 'supertokens-web-js/recipe/thirdpartyemailpassword/index.js'
import EmailVerification from 'supertokens-web-js/recipe/emailverification'
import ThirdPartyPasswordless from 'supertokens-web-js/recipe/thirdpartypasswordless'

// Initialises SuperTokens SDK for client side

// From the supertokens docs:
// The above code snippet sets up session management network interceptors on the frontend.
// Our frontend SDK will now be able to automatically save and add session tokens to each request to your API layer and also do auto session refreshing.
export default defineNuxtPlugin((_nuxtApp) => {
  // get the current domain from window
  const port = window.location.port ? `:${window.location.port}` : ''
  const domain = window.location.hostname + port || useRuntimeConfig().public.authApiDomain

  const app = SuperTokens.init({
    appInfo: {
      apiDomain: domain,
      apiBasePath: useRuntimeConfig().public.authApiBasePath,
      appName: useTenantConfig().auth?.appName || 'WeTix'
    },
    recipeList: [
      EmailVerification.init(),
      Session.init({
        tokenTransferMethod: useRuntimeConfig().public.authMobileMode ? 'header' : 'cookie' // Use header if running for mobile, or cookie for browser clients.
      }),
      ThirdPartyEmailPassword.init(),
      ThirdPartyPasswordless.init()
    ]
  })

  return {
    provide: {
      authApp: app, // inject $authApp helper to nuxt context
      authSession: Session // inject $authSession helper to nuxt context
    }
  }
})
