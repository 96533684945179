<template>
  <div>
    <section class="bg-white dark:bg-gray-900 ">
      <div class="container flex items-center min-h-screen px-6 py-12 mx-auto">
        <div>
          <p class="text-sm font-medium text-blue-500 dark:text-blue-400">
            {{ error.statusCode }} error
          </p>
          <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
            {{ error.statusMessage || 'Oops! We have a problem.' }}
          </h1>
          <p class="mt-4 text-gray-500 dark:text-gray-400">
            {{ error.message || 'An error occured. If this keeps happening, please contact support.' }}
          </p>

          <div class="flex items-center mt-6 gap-x-3">
            <button
              class="flex items-center justify-center w-1/2 px-5 py-2 text-sm text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-700"
              @click="handleError"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5 rtl:rotate-180"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
              </svg>

              <span>Go back</span>
            </button>

            <!-- <button class="w-1/2 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 bg-blue-500 rounded-lg shrink-0 sm:w-auto hover:bg-blue-600 dark:hover:bg-blue-500 dark:bg-blue-600">
              Take me home
            </button> -->
          </div>
        </div>
      </div>
    </section>
    <div class="min-h-full pt-16 pb-12 flex flex-col bg-white">
      <main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex-shrink-0 flex justify-center">
          <a href="/" class="inline-flex">
            <span class="sr-only">WeTix</span>
            <!-- <img class="h-12 w-auto" src="/images/wetix-logo.png" alt="Wetix"> -->
          </a>
        </div>
        <div class="py-16">
          <div class="text-center">
            <p class="text-sm font-semibold text-primary-600 uppercase tracking-wide">
              Error {{ error.statusCode
              }}
            </p>
            <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              {{
                error.statusMessage || 'Oops!' }}
            </h1>
            <p class="mt-2 text-base text-gray-500">
              Sorry, something went wrong.
            </p>
            <p class="mt-2 text-base text-gray-400">
              {{ error?.message }}
            </p>
            <pre class="mt-4 mb-4">{{ error }}</pre>
            <button class="text-base font-medium text-primary-600 hover:text-primary-500" @click="handleError">
              <span aria-hidden="true">&larr;</span>
              Go back
            </button>
            <!-- <div class="mt-6">
                            <a
                                @click="handleError"
                                class="text-base font-medium text-primary-600 hover:text-primary-500"
                            >Go back home</a>
                        </div>-->
          </div>
        </div>
      </main>
      <footer class="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav class="flex justify-center space-x-4">
          <NuxtLink href="https://wetix.support" class="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact Support
          </NuxtLink>
          <span class="inline-block border-l border-gray-300" aria-hidden="true" />
          <NuxtLink href="https://status.wetix.run/" class="text-sm font-medium text-gray-500 hover:text-gray-600">
            Service Status
          </NuxtLink>
          <!-- <span class="inline-block border-l border-gray-300" aria-hidden="true" />
                    <a
                        href="#"
                        class="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >Twitter</a>-->
        </nav>
      </footer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { H3Error } from 'h3'
import { NuxtError } from '#app'

const props = defineProps<{
  error: Partial<H3Error> | Partial<NuxtError> | H3Error | NuxtError
}>()

const { error } = toRefs(props)

const handleError = () => {
  // Go back
  history.back()
  // clearError({ redirect: '/' })
}
</script>
