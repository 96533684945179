import { default as dynamic_45loginfyG6LTf01aMeta } from "/vercel/path0/frontend/tixx-auth-frontend/pages/dynamic-login.vue?macro=true";
import { default as indexT0EfY5QLeVMeta } from "/vercel/path0/frontend/tixx-auth-frontend/pages/index.vue?macro=true";
import { default as testL3S01lFsUSMeta } from "/vercel/path0/frontend/tixx-auth-frontend/pages/test.vue?macro=true";
import { default as createdv5L5W6BQS7Meta } from "/vercel/path0/frontend/tixx-base/pages/account/created.vue?macro=true";
import { default as indexRkas4KYVCwMeta } from "/vercel/path0/frontend/tixx-base/pages/account/index.vue?macro=true";
import { default as devnJFZZ0XcBWMeta } from "/vercel/path0/frontend/tixx-base/pages/dev.vue?macro=true";
import { default as indexjW1gixgdRYMeta } from "/vercel/path0/frontend/tixx-base/pages/login/index.vue?macro=true";
import { default as otp1jP78KttjIMeta } from "/vercel/path0/frontend/tixx-base/pages/login/otp.vue?macro=true";
import { default as signed_45outQUeeZLKzduMeta } from "/vercel/path0/frontend/tixx-base/pages/signed-out.vue?macro=true";
export default [
  {
    name: dynamic_45loginfyG6LTf01aMeta?.name ?? "dynamic-login",
    path: dynamic_45loginfyG6LTf01aMeta?.path ?? "/dynamic-login",
    meta: dynamic_45loginfyG6LTf01aMeta || {},
    alias: dynamic_45loginfyG6LTf01aMeta?.alias || [],
    redirect: dynamic_45loginfyG6LTf01aMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-auth-frontend/pages/dynamic-login.vue").then(m => m.default || m)
  },
  {
    name: indexT0EfY5QLeVMeta?.name ?? "index",
    path: indexT0EfY5QLeVMeta?.path ?? "/",
    meta: indexT0EfY5QLeVMeta || {},
    alias: indexT0EfY5QLeVMeta?.alias || [],
    redirect: indexT0EfY5QLeVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-auth-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: testL3S01lFsUSMeta?.name ?? "test",
    path: testL3S01lFsUSMeta?.path ?? "/test",
    meta: testL3S01lFsUSMeta || {},
    alias: testL3S01lFsUSMeta?.alias || [],
    redirect: testL3S01lFsUSMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-auth-frontend/pages/test.vue").then(m => m.default || m)
  },
  {
    name: createdv5L5W6BQS7Meta?.name ?? "account-created",
    path: createdv5L5W6BQS7Meta?.path ?? "/account/created",
    meta: createdv5L5W6BQS7Meta || {},
    alias: createdv5L5W6BQS7Meta?.alias || [],
    redirect: createdv5L5W6BQS7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-base/pages/account/created.vue").then(m => m.default || m)
  },
  {
    name: indexRkas4KYVCwMeta?.name ?? "account",
    path: indexRkas4KYVCwMeta?.path ?? "/account",
    meta: indexRkas4KYVCwMeta || {},
    alias: indexRkas4KYVCwMeta?.alias || [],
    redirect: indexRkas4KYVCwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: devnJFZZ0XcBWMeta?.name ?? "dev",
    path: devnJFZZ0XcBWMeta?.path ?? "/dev",
    meta: devnJFZZ0XcBWMeta || {},
    alias: devnJFZZ0XcBWMeta?.alias || [],
    redirect: devnJFZZ0XcBWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-base/pages/dev.vue").then(m => m.default || m)
  },
  {
    name: indexjW1gixgdRYMeta?.name ?? "login",
    path: indexjW1gixgdRYMeta?.path ?? "/login",
    meta: indexjW1gixgdRYMeta || {},
    alias: indexjW1gixgdRYMeta?.alias || [],
    redirect: indexjW1gixgdRYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-base/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: otp1jP78KttjIMeta?.name ?? "login-otp",
    path: otp1jP78KttjIMeta?.path ?? "/login/otp",
    meta: otp1jP78KttjIMeta || {},
    alias: otp1jP78KttjIMeta?.alias || [],
    redirect: otp1jP78KttjIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-base/pages/login/otp.vue").then(m => m.default || m)
  },
  {
    name: signed_45outQUeeZLKzduMeta?.name ?? "signed-out",
    path: signed_45outQUeeZLKzduMeta?.path ?? "/signed-out",
    meta: signed_45outQUeeZLKzduMeta || {},
    alias: signed_45outQUeeZLKzduMeta?.alias || [],
    redirect: signed_45outQUeeZLKzduMeta?.redirect || undefined,
    component: () => import("/vercel/path0/frontend/tixx-base/pages/signed-out.vue").then(m => m.default || m)
  }
]