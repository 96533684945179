export default defineAppConfig({

  // Set the tixxAppScope here to a unique ID for each app. The appConfig will sync from KV storage during SSR
  // and be merged into the apps appConfig. This enables full whitelabelling and custom domains for tenants.
  tixxAppScope: 'default', // Set to eg 'dash' or 'billing' for each app. Should not be changed!

  authBackendUrl: 'http://localhost:8089',

  tixGlobal: {
    name: 'Hello from Nuxt layer'
  }

  // Dynamic Tenant Config - see documentation in Notion - https://www.notion.so/wetix/Tenant-Config-Proxy-c6baab981b944155ac906eb763835cd2?pvs=4
  // THIS CONFIG IS OVERWRITTEN BY OUR CUSTOM TENANT CONFIG MIDDLEWARE
  // tenantConfig: {
  //   tenantId: 'default',
  //   tenantConfigVersion: '25-04-2023',
  //   tenantConfigModelVersion: 1,
  //   // Custom theming config
  //   theme: {
  //     brandName: 'Tixx',
  //     // Colors
  //     primaryColor: '#FFC107',
  //     secondaryColor: '#FFC107',
  //     // Logo to be used on a dark background:
  //     brandLogoDarkImageId: '0fd440a3-720c-44e4-ceb6-b7f28ace9600', // Cloudflare images ID
  //     brandLogoDarkUrl: 'https://assets-cdn.wetix.co/cdn-cgi/imagedelivery/_T9XbrTSKaaBQ_3RCIlZVg/0fd440a3-720c-44e4-ceb6-b7f28ace9600/public',
  //     // Logo to be used on a light background:
  //     brandLogoLightImageId: '02d73f6b-c701-4b63-166b-56adbc0a2d00', // Cloudflare images ID
  //     brandLogoLightUrl: 'https://assets-cdn.wetix.co/cdn-cgi/imagedelivery/_T9XbrTSKaaBQ_3RCIlZVg/02d73f6b-c701-4b63-166b-56adbc0a2d00/public',
  //     // Brand emblem (preferably SVG)
  //     brandEmblemImageId: '02d73f6b-c701-4b63-166b-56adbc0a2d00', // Cloudflare images ID
  //     brandLogoEmblemUrl: 'https://assets-cdn.wetix.co/cdn-cgi/imagedelivery/_T9XbrTSKaaBQ_3RCIlZVg/22d59a72-81b3-42a3-9b69-95589e473b00/512sq',
  //     // Brand favicon URL
  //     brandFaviconUrl: 'https://assets-cdn.wetix.co/cdn-cgi/imagedelivery/_T9XbrTSKaaBQ_3RCIlZVg/3940ce59-d83c-4296-76d0-c67083c1fb00/width=48,height=48',
  //     // Alt text for brand logos
  //     brandLogoAlt: 'Tixx'
  //   },
  //   // Dynamic Tenant Links
  //   links: {
  //     defaultHomeLinkUrl: 'https://wetix.net',
  //     termsUrl: 'https://wetix.net/terms',
  //     privacyUrl: 'https://wetix.net/privacy',
  //     supportUrl: 'https://wetix.net/support',
  //     contactUrl: 'https://wetix.net/contact',
  //     aboutUrl: 'https://wetix.net/about'
  //   },
  //   // Custom config for the auth service
  //   auth: {
  //     appName: 'Tixx',
  //     loginStyle: 'default', // 'default' | 'advanced'   // Defines which login component is used for login.
  //     enableEmailPassword: false,
  //     enableSocial: false,
  //     enablePasswordless: true,
  //     loginBackgroundImageUrl: 'https://public-assets.wetix.net/wetix-generic-background-blue.jpg',
  //     defaultLoginBrandImageId: 'c8db2811-2f1d-4527-7a69-b58e1a48c800',
  //     showBackgroundImage: true,
  //     brandAuthFooterLegalText: '© WeTix Ltd - All Rights Reserved'
  //   },
  //   meta: {
  //     tenantConfigModelVersion: 1,
  //     tenantConfigSource: 'nuxt-app-default'
  //   }
  // }
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    myLayer?: {
      /** Project name */
      name?: string
    }
  }
}
