// Calls the API to get the latest tenant config and updates the appConfig with it
// We can do: useUpdateTenantConfig().tenantId
export const useUpdateTenantConfig = () => {
  const appScope = useRuntimeConfig().public.tixxAppScope
  const { data } = useFetch(`/api/v2/_config/get/${appScope}`) as any // This will be cached
  const tenantConfig = data.value
  // console.info('useUpdateTenantConfig got tenantConfig for app scope:', { appScope, tenantConfig })
  const latestTenantConfig = tenantConfig
  if (latestTenantConfig) {
    // Update appConfig with latest tenantConfig
    // console.log('useUpdateTenantConfig updating appConfig with latest tenantConfig from backend')
    updateAppConfig({ tenantConfig: latestTenantConfig })
  }

  return tenantConfig
}
