// Intercepts incoming requests
export default defineNuxtPlugin({
  name: 'tixx-req',
  enforce: 'pre', // or 'post'
  async setup (nuxtApp) {
    // event is the HTTP request event
    const event = useRequestEvent()

    // headers is the HTTP request headers
    const headers = useRequestHeaders()

    // Get only cookie request header
    const cookies = useRequestHeaders(['cookie'])

    // In the browser, useRequestEvent() will return undefined. It's available on the server only.
    if (event) {
      const url = event.node?.req?.url
      const host = event.node?.req?.headers?.host

      // Detect if we have access cloudflare context
      const hasCloudflareContext = event?.context?.cloudflare ? true : false || false

      // Construct debug info to report to our http bin
      const eventDebugInfo = {
        tenantId: event.context?.tenantId,
        host,
        url,
        hasCloudflareContext,
        cloudflareContext: {},
        httpVersion: event.node?.req?.httpVersion,
        httpMethod: event.node?.req?.method,
        headers,
        cookies,
        nuxtConfig: { ...nuxtApp.$config }
      }

      // If in Cloudflare context, waitUntil allows cache writes, external logging, etc without blocking the event
      if (hasCloudflareContext) {
        console.log('Running with cloudflare context', hasCloudflareContext)
        eventDebugInfo.cloudflareContext = { ...event?.context?.cloudflare }
        // const { cloudflare } = event.context
        // cloudflare?.context?.waitUntil(logRequest(event.node.req))
      }

      // console.log('Got event')
      // Push a log of all requests to an external endpoint
      if (useRuntimeConfig().debugRequestLogEndpoint) {
        console.log(`Reporting debug SSR event to ${useRuntimeConfig().debugRequestLogEndpoint} `)
        await $fetch(`${useRuntimeConfig().debugRequestLogEndpoint}`, {
          method: 'POST',
          body: JSON.stringify(eventDebugInfo)
        })

        // Set host info in state for re-use on frontend.
        useState('contextHost', () => eventDebugInfo.host)
      }
    } else {
      // We're in the browser
      // console.log('requestIntercept called in browser')
    }
  },
  hooks: {
    // // You can directly register Nuxt app hooks here
    // 'app:created'() {
    //   const nuxtApp = useNuxtApp()
    //   //
    // }
  }
})
