import revive_payload_client_Z9iRsS8QUl from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.0_eslint@8.44.0_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_85y9539Bvr from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.0_eslint@8.44.0_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import requestIntercept_ht3MGJLfzZ from "/vercel/path0/frontend/tixx-auth-frontend/plugins/requestIntercept.ts";
import _00_requestIntercept_5mhqMrf3J2 from "/vercel/path0/frontend/tixx-base/plugins/00.requestIntercept.ts";
import _02_tenantConfig_WenyNC06rv from "/vercel/path0/frontend/tixx-base/plugins/02.tenantConfig.ts";
import components_plugin_KR1HBZs4kY from "/vercel/path0/frontend/tixx-auth-frontend/.nuxt/components.plugin.mjs";
import unhead_m13lVDm7xX from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.0_eslint@8.44.0_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_kybjIxzfrC from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.0_eslint@8.44.0_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import config_2dPLqLoM56 from "/vercel/path0/node_modules/.pnpm/@huntersofbook+naive-ui-nuxt@1.0.0_vue@3.3.4/node_modules/@huntersofbook/naive-ui-nuxt/dist/runtime/config.mjs";
import plugin_Mr3gKoia6z from "/vercel/path0/node_modules/.pnpm/@huntersofbook+naive-ui-nuxt@1.0.0_vue@3.3.4/node_modules/@huntersofbook/naive-ui-nuxt/dist/runtime/plugin.mjs";
import plugin_client_rMDYCVR6s7 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_Rpbq5oMYSg from "/vercel/path0/node_modules/.pnpm/nuxt@3.6.2_@types+node@20.4.0_eslint@8.44.0_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import supertokens_client_x8Cyaci4XP from "/vercel/path0/frontend/tixx-auth-frontend/plugins/supertokens.client.ts";
export default [
  revive_payload_client_Z9iRsS8QUl,
  router_85y9539Bvr,
  requestIntercept_ht3MGJLfzZ,
  _00_requestIntercept_5mhqMrf3J2,
  _02_tenantConfig_WenyNC06rv,
  components_plugin_KR1HBZs4kY,
  unhead_m13lVDm7xX,
  prefetch_client_kybjIxzfrC,
  config_2dPLqLoM56,
  plugin_Mr3gKoia6z,
  plugin_client_rMDYCVR6s7,
  chunk_reload_client_Rpbq5oMYSg,
  supertokens_client_x8Cyaci4XP
]